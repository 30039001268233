import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Header.scss";
import logo from "../assets/image/logo/logo.png";
import selectDown from "../assets/image/select-down.svg";
import { Link } from "react-router-dom";
import hambergerIcon from "../assets/image/hamberger.svg";
import hambergerClose from "../assets/image/hamberger-close.png";
import useTranslationCustom from "../assets/image/community-section-1/useTranslationCustom";
import { getLanguages } from "../../locales";
import useRouter from "../router/useRouter";

function Header() {
  const selectRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpenLanguageMenu(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [selectRef]);
  const router = useRouter();
  const { i18n } = useTranslationCustom({
    prefix: "",
    nameSpace: "global_navigation",
  });

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const onClickLanguageOpenButton = () => {
    setIsOpenLanguageMenu((state) => !state);
  };
  const supportedLanguages = useMemo(() => {
    return getLanguages();
  }, []);
  const onChangeLanguage = (code) => {
    i18n.changeLanguage(code);
    sessionStorage.setItem("language", code);
  };

  const [currentScroll, setCurrentScroll] = useState(false);

  const checkScroll = useCallback(() => {
    setCurrentScroll(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, [checkScroll]);

  const [selectedRouter, setSelectedRouter] = useState();

  return (
    <header
      className="header"
      style={{
        ...(isOpenMobileMenu && { height: "100%" }),
        ...((currentScroll > 90 || selectedRouter || isOpenMobileMenu) && {
          backgroundColor: "black",
        }),
      }}
      onMouseLeave={() => setSelectedRouter(undefined)}
    >
      <div className="header-container container">
        <div className="FLEX_ROW_ALIGN_CENTER">
          <Link to={"/"} onClick={() => setIsOpenMobileMenu((prev) => false)}>
            <img className="logo" src={logo} alt="logo" />
          </Link>
          <div className="header-menu">
            {router.map((route, index) => (
              <div key={`h_${index}`}>
                <div className={"menu"} key={`${route.label}_${index}`} onMouseEnter={() => setSelectedRouter(route)} onClick={() => setSelectedRouter(route)}>
                  {route.label}
                </div>
                <div
                  className="header-sub-menu"
                  style={{
                    height: selectedRouter && selectedRouter.label === route.label && selectedRouter.sub?.length > 0 ? 60 : 0,
                  }}
                >
                  {selectedRouter?.label === route.label &&
                    selectedRouter?.sub?.map((sub, index) => (
                      <Link key={`l_${index}`} to={sub.link} className="sub-menu" onClick={() => setSelectedRouter(undefined)}>
                        {sub.label}
                      </Link>
                    ))}
                  <div className="header-sub-menu-background" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="select-lang" ref={selectRef}>
          <button className="select-lang-button" onClick={onClickLanguageOpenButton}>
            {supportedLanguages[i18n.language]}
            <img className={"select-lang-button-icon"} src={selectDown} alt={"select"} />
          </button>
          <div className={`select-lang-container ${isOpenLanguageMenu && "visible"}`}>
            {Object.keys(supportedLanguages).map((el, index) => (
              <div
                key={`h_${index}`}
                className="select-lang-container-button"
                onClick={() => {
                  onChangeLanguage(el);
                  setIsOpenLanguageMenu(false);
                }}
              >
                {supportedLanguages[el]}
              </div>
            ))}
          </div>
        </div>

        <div className="header-hamberger">
          <button onClick={() => setIsOpenMobileMenu((prev) => !prev)}>
            <img className="header-hamberger-icon" src={isOpenMobileMenu ? hambergerClose : hambergerIcon} alt="hamberger" />
          </button>
        </div>
      </div>
      {isOpenMobileMenu && (
        <div className="header-mobile-menu">
          {router.map((route, index) => (
            <div className="header-mobile-menu-column" key={`f_${index}`}>
              <div className={"main"}>{route.label}</div>
              {route?.sub?.map((item, j) => (
                <div className="header-mobile-menu-column-tab">
                  <Link to={item.link} onClick={() => setIsOpenMobileMenu(false)} key={`l_${j}`} className="header-mobile-menu-column-tab-item">
                    {item.label}
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </header>
  );
}

export default Header;
