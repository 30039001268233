import React from "react";
import "./BrandSection1.scss";
import logo from "../../assets/image/logo/logo.png";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";

const BrandSection1 = () => {
  const { t: brandSection1Text } = useTranslationCustom({
    prefix: "section1",
    nameSpace: "brand",
  });

  const aiFileDownloadButtonHandler = () => {
    window.open(process.env.REACT_APP_EQBR_CI_FILE_AI);
  };
  const pngFileDownloadButtonHandler = () => {
    window.open(process.env.REACT_APP_EQBR_CI_FILE_PNG);
  };

  return (
    <div className="brand-section-1 container">
      <div className="brand-section-1-eqbr-ci">EQBR CI</div>
      <div className="brand-section-1-eqbr-logo">
        <img className="brand-section-1-eqbr-logo-img" src={logo} alt="" />
      </div>
      <div className="brand-section-1-eqbr-ci-file">
        <div className="brand-section-1-eqbr-ci-file-title">{brandSection1Text("title")}</div>
        <div className="brand-section-1-eqbr-ci-file-download">
          <div className="brand-section-1-eqbr-ci-file-download-button" onClick={aiFileDownloadButtonHandler}>
            <div className="brand-section-1-eqbr-ci-file-download-button-title">{brandSection1Text("AI_button")}</div>
            <div className="brand-section-1-eqbr-ci-file-download-button-size">328 KB</div>
          </div>
          <div className="brand-section-1-eqbr-ci-file-download-button" onClick={pngFileDownloadButtonHandler}>
            <div className="brand-section-1-eqbr-ci-file-download-button-title">{brandSection1Text("PNG_button")}</div>
            <div className="brand-section-1-eqbr-ci-file-download-button-size">133 KB</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandSection1;
